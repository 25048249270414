.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* make the customizations */
$theme-colors: (
  "primary": #005ea6,
  "secondary": #ffffff,
  "tertiary": #6c757d,
  "light": #dee2e6,
  // "info": tomato,
  // "danger": teal

  "DRAFT": #eeeeee, // 238 238 238
  "SUBMITTED": #c1d5f4, // 193 213 244
  "ACCEPTED_PAID": #d4e6d0, // 212 230 208
  "MILESTONE_ACCEPTED": #326a29, // 50 106 41
  "MILESTONE_CLAIMED": #ffd16e, // 255 209 110
  "REJECTED": #c80017, // 200 0 23
  "REVIEW_REQUIRED": #f3c4c6, // 243 196 198
  "DISPUTED": #c80017, // 200 0 23
  "EXPIRED": #c80017, // 200 0 23
  "PAYMENT_SENT": #326a29, // 50 106 41
  "SYSTEM_SIGNED": #9c446c // 156 68 108
);

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375), //150px
    15: ($spacer * 18)     //300px
  ),
  $spacers
);

.rce-mbox-text {
  white-space: pre-wrap;
}

@import "~bootstrap/scss/bootstrap";

@media print {
  body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}